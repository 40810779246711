<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="国家" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.country" placeholder="请输入国家..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="来路" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.referrer" placeholder="请输入来路..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="入口地址" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.url" placeholder="请输入入口地址..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="ip" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.ip" placeholder="请输入ip..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="日期" colon :labelCol='labelcol' :wrapperCol="wrappercol"  class="ant-btn-box">
            <Button type="primary" size={size} :class="antBtnPrimary[0]" @click="date = Day; ">今日</Button>
            <Button type="primary" size={size} :class="antBtnPrimary[1]" @click="date = yesterday;">昨日</Button>
            <Button type="primary" size={size} :class="antBtnPrimary[2]" @click="date = beforeDay;" style="margin-right:10px;">前日</Button>
            <a-date-picker placeholder="选择日期" v-model="date" @change="onChangeDate" format="YYYY-MM-DD"/>
            <!--a-date-picker placeholder="选择日期" v-model="form.date"@change="onChangeDate"  format="YYYY-MM-DD"/-->
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="设备" colon :labelCol='labelcol' :wrapperCol="wrappercol"  class="ant-btn-box">
            <Button type="primary" size={size} :class="is_mobile[0]" @click="form.is_mobile=0;is_mobile=['ant-btn-primary arr','ant-btn-primary','ant-btn-primary']">全部</Button>
            <Button type="primary" size={size} :class="is_mobile[1]" @click="form.is_mobile=2;is_mobile=['ant-btn-primary','ant-btn-primary arr','ant-btn-primary']">电脑端</Button>
            <Button type="primary" size={size} :class="is_mobile[2]" @click="form.is_mobile=1;is_mobile=['ant-btn-primary','ant-btn-primary','ant-btn-primary arr']">移动端</Button>
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>
            <a-button type="primary" @click="returnFn">
              返回
            </a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>
<!--:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"复选框-->
      <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :columns="columns"
          :row-key="record => record.site_id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          bordered
          @change="handleTableChange"
          :expandIconAsCell="false"
          :expandIconColumnIndex="8"
          :expandIcon="expandIcon"
      >
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">
              <span>
              </span>

        </template>
        <!-- 额外展开的行 -->
        <a-table
            class="noBorder"
            slot="expandedRowRender"
            :size="'small'"
            slot-scope="record"
            :columns="innerColumns"
            :data-source="record.list"
            :pagination="false"
        >
        </a-table>
        <!-- 额外展开的行结束 -->

      </a-table>
    </div>



  </div>
</template>
<script>
import {myMixin} from "@/static/js/mixin.js"
import {historyList} from "@/axios/stats";
import moment from 'moment';
const columns = [//列描述

  {align:'center', title: '访问ID', dataIndex: 'visitor_id'},
  {align:'center', title: '国家', dataIndex: 'country'},
  {align:'center', title: '语言', dataIndex: 'language'},
  {align:'center', title: 'ua', dataIndex: 'ua' },
  {align:'center', title: 'IP', dataIndex: 'ip'},
  {align:'center', title: '访问时间', dataIndex: 'time'},
  {align:'center', title: '入口页', dataIndex: 'url'},
  {align:'center', title: '来路', dataIndex: 'referrer'},
  {align:'center',title: '操作',scopedSlots: { customRender: '操作', },},


];
const innerColumns = [//额外展开的行的字段
  { title: 'ID', dataIndex: 'id'},
  { title: '地址', dataIndex: 'url' },
  { title: '来源', dataIndex: 'referrer' },
  { title: '时间', dataIndex: 'visit_time' },
];
const dateFormatList = ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'];

export default {
  filters: {

  },
  mixins: [myMixin],
  data() {

    return {

      languagesList:[],
      form: {
        country:'',
        is_mobile:0,
        url:'',
        referrer:'',
        ip:'',
        date:'',
        page_size: 10,
        page:1,
        site_id:'',
      },

      columns,//列描述

      //confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        domain:'',
      },
      editFormRules: {
        domain: [
          { required: true, message: '请输入网站域名', trigger: 'blur' },
        ],
      },
      title:'编辑ES',
      // languagesList:[]
      innerColumns,//额外展开的行的字段
      time:[],
      antBtnPrimary:['ant-btn-primary arr','ant-btn-primary','ant-btn-primary'],
      Day:'',
      yesterday:'',
      beforeDay:'',
      date:'',
      is_mobile:['ant-btn-primary arr','ant-btn-primary','ant-btn-primary'],
    }

  },
created() {
  this.date = this.$route.query.time;
  this.Day =moment().format('YYYY-MM-DD');
  this.yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
  this.beforeDay = moment().subtract(2, 'days').format("YYYY-MM-DD");
},
  watch:{
    date(){
      if(this.date === this.Day ){
        this.antBtnPrimary = ['ant-btn-primary arr','ant-btn-primary','ant-btn-primary'];
      }else if(this.date === this.yesterday ){
        this.antBtnPrimary = ['ant-btn-primary','ant-btn-primary arr','ant-btn-primary'];
      }else if(this.date === this.beforeDay ){
        this.antBtnPrimary = ['ant-btn-primary','ant-btn-primary','ant-btn-primary arr'];
      }else{
        this.antBtnPrimary = ['ant-btn-primary','ant-btn-primary','ant-btn-primary'];
      }
    }
  },
  mounted() {
    this.form.site_id= this.$route.query.id;
    let that=this

    this.fetch();

  },
  methods:{
    moment,
    onChangeDate(date, dateString) {//时间
      this.date = dateString;
    },
    async fetch (params = {}) {
      this.form.date = this.date;
      this.loading = true;
      let data = await historyList({...params,...this.form})


      this.loading = false;
      const pagination = { ...this.pagination };
      pagination.total = data.data.total;
      this.data = data.data.list;

      this.pagination = pagination;
    },

    returnFn( ){

      this.$router.push({path:'/stats'})
    },

    expandIcon(props) {
      if (props.record) {
        if (props.expanded) {//有数据-展开时候图标
          return (
              <a
                  style="color: 'black',margin-right:0px"
                  onClick={(e) => {
                    console.log(props.record,e)
                    props.onExpand(props.record, e);
                  }}
              >
                <a-button size="small" type="primary" >展开详情</a-button>
              </a>
          );
        } else {//有数据-未展开时候图标
          return (
              <a
                  style="color: 'black' ,margin-right:0px"
                  onClick={(e) => {
                    props.onExpand(props.record, e);
                  }}
              >
                <a-button size="small" type="primary" >展开详情</a-button>
              </a>
          );
        }
      } else {//无数据-图标

        return (
            <span style="margin-right:0px">
              <a-icon type="smile" />
            </span>
        );
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.ant-btn-box{
display: flex;
}
.ant-btn-box .ant-calendar-picker{
  width: 51%!important
}
.ant-btn-box .ant-btn-primary{
  width: 50px;
  padding: 0;
  margin: 0 -1px 0 0;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: #ffff;
  color: #000;
  border: 1px #c0c0c0 solid;
}
.ant-btn-box .ant-btn-primary.arr{
  background-color: #119aa5;
  color: #fff;
}
@media (max-width: 1200px){
  .ant-btn-box{
    display: block;
  }
  .ant-btn-box .ant-calendar-picker{
    width: 100% !important;
  }
}

</style>
